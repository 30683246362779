import React from 'react';
import {mediaBaseURL} from "../../../utils/constant";
import {checkData} from "../../../helper";
import {Col, Container, Row} from "react-bootstrap";
// import image01 from "../../../images/location/map.png";
import Pingif from "../../../images/location/map_image.jpg";
import Link from "gatsby-link";

function LocationPin({data}) {
    return (
        <section className="location_pin p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={6} lg={5} xl={5} xxl={5} className="my-auto">
                        <div className="example" data-text="Locations">
                            <h2>Locations</h2>
                        </div>

                        <h4>Meet Our Experts One-On-One</h4>
                        <p>{checkData(data, 'location_address')}</p>
                        <div className="location_boxes">
                            {data?.location_calltoaction?.map((el, i) => (
                                <div className="box_items">
                                    <div className="icons_box">
                                        <img
                                            src={`${mediaBaseURL}${checkData(el, 'image')}`}
                                            className="img-fluid"
                                            alt="call_to_action"/>
                                    </div>
                                    <div className="icons_content">
                                        <h5>
                                            <Link to={checkData(el, 'links')}>
                                                <div dangerouslySetInnerHTML={{__html: checkData(el, 'content')}}/>
                                            </Link>
                                        </h5>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={5} xl={5} xxl={5} className="offset-lg-2">
                        <p className="strong">{checkData(data, 'location_title')}</p>
                        <div className="pin_setting text-center">
                            <Link to={checkData(data, 'links')}>
                                <img
                                    src={Pingif}
                                    className="img-fluid"
                                    alt={checkData(data, 'location_title')}/>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default LocationPin;